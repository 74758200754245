export const LegalEntityTypes = [
    'Sole Proprietorship',
    'Public Corp',
    'Government',
    'Private Corp',
    'Publicly Traded Partnership',
    'Private Partnership',
    'Publicly Traded Limited Liability Company',
    'Private Limited Liability Company',
    'Association/Estate/Trust',
    'Tax Exempt Organization',
    'International/Organization',
] as const;
export type LegalEntityType = typeof LegalEntityTypes[number];

export const LegalEntityTypeOptions: {label: string, value: LegalEntityType}[] = [
    { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
    { label: 'Public Corp', value: 'Public Corp' },
    { label: 'Government', value: 'Government' },
    { label: 'Private Corp', value: 'Private Corp' },
    { label: 'Publicly Traded Partnership', value: 'Publicly Traded Partnership' },
    { label: 'Private Partnership', value: 'Private Partnership' },
    { label: 'Publicly Traded LLC', value: 'Publicly Traded Limited Liability Company' },
    { label: 'Private LLC', value: 'Private Limited Liability Company' },
    { label: 'Association/Estate/Trust', value: 'Association/Estate/Trust' },
    { label: 'Tax-Exempt Organization', value: 'Tax Exempt Organization' },
    { label: 'International/Organization', value: 'International/Organization' }
];
