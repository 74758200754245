import type { AllFields } from '@hooks/wizard/types';
import type { AccountApplication, USState } from '@hooks/types';
import { LegalEntityTypeOptions } from '@utils/static-options/entityType';
import { getStateOption } from '@utils/FormUtils';
import dayjs from 'dayjs';
import MONTHS from '@utils/static-options/month';
import { UpdateApplicationRequest, UpdateOwnerRequest } from './types';

/**
 * Map an individual form value to Account API request body property
 * @param name form field name
 * @param value form field value
 * @param values object representing all form values
 * @returns object representing an individual Account API property
 */
// FIXME(LY) Using string to allow for objects and arrays of objects like contact.FirstName
const mapApplicationValueToAPI = (name: (keyof AllFields) | string, value: any, values: AllFields): UpdateApplicationRequest => {
    switch (name) {
        case 'avgTransactionSize':
            return { processingProfile: { averageTicket: +value } };
        case 'businessAddr1':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { line1: value } }, legalEntity: { address: { line1: value } }
                } : {
                    business: { address: { line1: value } }
                }
        case 'businessAddr2':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { line2: value } }, legalEntity: { address: { line2: value } }
                } : {
                    business: { address: { line2: value } }
                }
        case 'businessCity':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { city: value } }, legalEntity: { address: { city: value } }
                } : {
                    business: { address: { city: value } }
                }
        case 'businessEmail':
            return { business: { email: value } };
        case 'businessPhone':
            return { business: { phone: value.replace(/\s/g, '') } };
        case 'businessState':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { state: value } }, legalEntity: { address: { state: value } }
                } : {
                    business: { address: { state: value } }
                }
        case 'businessTaxPayMethod':
            break;
        case 'businessZipCode':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { postalCode: value } }, legalEntity: { address: { postalCode: value } }
                } : {
                    business: { address: { postalCode: value } }
                }
        case 'contact.FirstName':
            return { contact: { firstName: value, controlProng: true, authorizedSigner: true } };  // hard-coding, we assume the "contact" will always be control prong & authorized signer
        case 'contact.LastName':
            return { contact: { lastName: value } };
        case 'contact.ContactNumber':
            return { contact: { phone: value.replace(/\s/g, '') } };
        case 'contact.SSN':
            return { contact: { ssn: value } };
        case 'contact.DateOfBirth': {
            const [month, day, year] = value.split('/');
            return { contact: { dob: `${year}-${month}-${day}` } };
        }
        case 'contact.Email':
            return { contact: { email: value } };
        case 'contact.Title':
            return { contact: { title: value } };
        case 'contact.IsOwner':
            return { contact: { isOwner: value === 'yes' } };
        case 'contact.PercentOwnership':
            return { contact: { percentOwnership: +value } };
        case 'contact.isPrimaryLocSameAsBusiness':
            return value === 'yes' ? {
                contact: {
                    address: {
                        line1: values.businessAddr1,
                        city: values.businessCity,
                        state: values.businessState?.value as any,
                        postalCode: values.businessZipCode,
                        ...(values?.businessAddr2?.length ? { line2: values.businessAddr2 } : {})
                    }
                }
            } : {
                contact: { address: {} } // Need to fix Account API not saving Address as {}
            };
        case 'contact.Addr1':
            return { contact: {address: { line1: value } } };
        case 'contact.Addr2':
            return { contact: {address: { line2: value } } };
        case 'contact.City':
            return { contact: {address: { city: value } } };
        case 'contact.State':
            return { contact: {address: { state: value } } };
        case 'contact.ZipCode':
            return { contact: {address: { postalCode: value } } };
        case 'dateOfEstb': {
            const [month, day, year] = value.split('/');
            return { legalEntity: { dateOfIncorporation: `${year}-${month}-${day}` } };
        }
        case 'dbaName':
            return { business: { dba: value } };
        case 'entityType':
            return { legalEntity: { type: value } };
        case 'expectedMonthlyCCVolume':
            return { processingProfile: { averageMonthlyCardVolume: +value } };
        case 'high_ticket_volume':
            return { processingProfile: { highestTicket: +value } };
        case 'internetPercentage':
            return { processingProfile: { acceptanceMethods: { internet: +value } } };
        case 'isCurrentlyAcceptingCreditCards':
            return value === 'yes' ? {
                processingProfile: {
                    averageMonthlyCardVolume: +(values.expectedMonthlyCCVolume || 0),
                    currentlyAcceptingCards: true,
                }
            } : {
                processingProfile: {
                    averageMonthlyCardVolume: 0,
                    currentlyAcceptingCards: false,
                }
            };
        case 'isSeasonal':
            return { processingProfile: { seasonal: value === 'yes' } };
        case 'keyedPercentage':
            return { processingProfile: { acceptanceMethods: { keyed: +value } } };
        case 'legalAddr1':
            return { legalEntity: { address: { line1: value } } };
        case 'legalAddr2':
            return { legalEntity: { address: { line2: value } } };
        case 'legalCity':
            return { legalEntity: { address: { city: value } } };
        case 'legalIsPhysicalAddress':
            return value === 'yes' ? {
                legalEntity: {
                    address: {
                        line1: values.businessAddr1,
                        city: values.businessCity,
                        state: values.businessState?.value as USState,
                        postalCode: values.businessZipCode,
                        ...(values?.businessAddr2?.length ? { line2: values.businessAddr2 } : {})
                    }
                }
            } : {
                legalEntity: {
                    address: {
                        line1: values.legalAddr1,
                        city: values.legalCity,
                        state: values.legalState?.value as USState,
                        postalCode: values.legalZipCode,
                        ...(values?.legalAddr2?.length ? { line2: values.legalAddr2 } : {})
                    }
                }
            };
        case 'legalName':
            return { legalEntity: { name: value } };
        case 'legalState':
            return { legalEntity: { address: { state: value } } };
        case 'legalZipCode':
            return { legalEntity: { address: { postalCode: value } } };
        case 'motoPercentage':
            return { processingProfile: { acceptanceMethods: { moto: +value } } };
        case 'primaryAccountBankName':
            return { banking: { bankName: value } };
        case 'primaryBankAccountHolderFullName':
            return { banking: { accountHolderName: value } };
        case 'primaryBankAccountNumber':
            return { banking: { depositAccount: value } };
        case 'primaryBankRoutingNumber':
            return { banking: { depositRouting: value } };
        case 'seasonalFrom':
            return { processingProfile: { seasonalFrom: +value } };
        case 'seasonalTo':
            return { processingProfile: { seasonalTo: +value } };
        case 'swipedPercentage':
            return { processingProfile: { acceptanceMethods: { swiped: +value } } };
        case 'tin':
            return { legalEntity: { tin: value } };
        case 'targetDate':
            break;
        case 'locationCount':
            break;
        case 'businessUrl':
            return { business: { websiteUrl: value } };
        case 'marketingWebsite':
            break;
        case 'marketingFile':
            break;
        case 'transactionCompletePeriod':
            break;
        case 'daysToReturnOrCancel':
            break;
        case 'isGSDeliveryAfterTxnDay':
            break;
        case 'delivery_immediate_Percentage':
            break;
        case 'delivery_1_3_DaysPercentage':
            break;
        case 'delivery_4_7_DaysPercentage':
            break;
        case 'delivery_8_14_DaysPercentage':
            break;
        case 'delivery_15_30_DaysPercentage':
            break;
        case 'delivery_Over_30_DaysPercentage':
            break;
        case 'recurringMonthly':
            break;
        case 'billingPeriod':
            break;
        case 'entireCreditCardCollet':
            break;
        case 'PCICompliant':
            break;
        case 'doesCustomerSignContract':
            break;
        case 'contractFile':
            break;
        case 'howCustomerOrder':
            break;
        case 'vendorPurchaseAddress':
            break;
        case 'serviceForCardHolder':
            break;
        case 'processingStatement1':
            break;
        case 'processingStatement2':
            break;
        case 'processingStatement3':
            break;
        case 'cnpAgreementRequired':
            break;
        case 'primaryOwnerFirstName':
            break;
        case 'primaryAccountFile':
            break;
        case 'extraFiles':
            break;
        case 'maskedPrimaryBankAccountNumber':
            break;
        default: {
            return {};
        }
    }
    return {};
}

// FIXME(LY) Using string to allow for objects and arrays of objects like people.1.FirstName
const mapOwnerValuesToAPI = (name: string, value: any, values: AllFields): UpdateOwnerRequest => {
    const [, i, field] = name.split('.');
    const id = values.people?.at(+i)?.id;
    switch (field) {
        case 'FirstName':
            return { id, firstName: value, controlProng: false };  // hard-coding, we assume the "contact" will always be control prong, not those which are passed as "additional owners"
        case 'LastName':
            return { id, lastName: value };
        case 'ContactNumber':
            return { id, phone: value.replace(/\s/g, '') };
        case 'SSN':
            return { id, ssn: value };
        case 'DateOfBirth': {
            const [month, day, year] = value.split('/');
            return { id, dob: `${year}-${month}-${day}` };
        }
        case 'Email':
            return { id, email: value };
        case 'Title':
            return { id, title: value };
        case 'PercentOwnership':
            return { id, percentOwnership: +value };
        case 'isPrimaryLocSameAsBusiness':
            return value === 'yes' ? {
                id,
                address: {
                    line1: values.businessAddr1,
                    city: values.businessCity,
                    state: values.businessState?.value as any,
                    postalCode: values.businessZipCode,
                    ...(values?.businessAddr2?.length ? { line2: values.businessAddr2 } : {})
                }
            } : {
                id,
                address: {} // Need to fix Account API not saving Address as {}
            };
        case 'Addr1':
            return { id, address: { line1: value } };
        case 'Addr2':
            return { id, address: { line2: value } };
        case 'City':
            return { id, address: { city: value } };
        case 'State':
            return { id, address: { state: value } };
        case 'ZipCode':
            return { id, address: { postalCode: value } };
        default:
            return {};
    }
};

export const mapFormValueToPfacAPI = (name: (keyof AllFields) | string, value: any, values: AllFields) => name.startsWith('people')
    ? ({ _type: 'owner' as const, ...mapOwnerValuesToAPI(name, value, values) })
    : ({ _type: 'application' as const, ...mapApplicationValueToAPI(name, value, values) });

export const mapAPIValuesToForm = (values: AccountApplication): Partial<AllFields> => {
        const legalIsPhysicalAddress = values?.business?.address?.line1 === values?.legalEntity?.address?.line1
        && values?.business?.address?.city === values?.legalEntity?.address?.city
        && values?.business?.address?.line2 === values?.legalEntity?.address?.line2
        && values?.business?.address?.postalCode === values?.legalEntity?.address?.postalCode
    return {
        dbaName: values?.business?.dba,
        businessPhone: values?.business?.phone,
        businessEmail: values?.business?.email,
        businessUrl: values.business.websiteUrl,
        businessAddr1: values?.business?.address?.line1 || "",
        businessAddr2: values?.business?.address?.line2,
        businessCity: values?.business?.address?.city || "",
        businessState: getStateOption(values.business?.address?.state),
        businessZipCode: values?.business?.address?.postalCode || '',
        legalName: values?.legalEntity?.name,
        tin: values?.legalEntity?.tin?.toString() || "",
        legalIsPhysicalAddress: legalIsPhysicalAddress ? "yes" : "no",
        legalAddr1: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.line1,
        legalAddr2: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.line2,
        legalCity: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.city,
        legalState: legalIsPhysicalAddress ? undefined : getStateOption(values.legalEntity.address?.state),
        legalZipCode: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.postalCode,
        entityType: values?.legalEntity?.type && LegalEntityTypeOptions?.find((entity: { label: string, value: string }) => entity.value === values.legalEntity.type),
        dateOfEstb: values?.legalEntity?.dateOfIncorporation && dayjs(values?.legalEntity?.dateOfIncorporation, { format: "YYYY-MM-DD" }).format("MM/DD/YYYY"),
        people: values.people?.length ? values?.people?.map?.((person) => {
            const sameAsPhysical = values?.business?.address?.line1 === person?.address?.line1
                && values?.business?.address?.city === person?.address?.city
                && values?.business?.address?.line2 === person?.address?.line2
                && values?.business?.address?.postalCode === person?.address?.postalCode
            return ({
                isPrimaryLocSameAsBusiness: sameAsPhysical ? 'yes' : 'no',
                Addr1: !sameAsPhysical && person?.address?.line1 ? person?.address?.line1 : "",
                Addr2: !sameAsPhysical && person?.address?.line2 ? person?.address?.line2 : "",
                City: !sameAsPhysical && person?.address?.city ? person?.address?.city : "",
                ContactNumber: person?.phone || "",
                DateOfBirth: person?.dob ? dayjs(person.dob, { format: "YYYY-MM-DD" }).format("MM/DD/YYYY") : "",
                Email: person?.email || "",
                FirstName: person?.firstName || "",
                id: person.id || "",
                SSN: undefined,
                LastName: person.lastName || "",
                PercentOwnership: person.percentOwnership || undefined,
                State: !sameAsPhysical && getStateOption(person.address?.state) || undefined,
                Title: person.title ? { label: person.title, value: person.title } : undefined,
                ZipCode: !sameAsPhysical && person?.address?.postalCode ? person?.address?.postalCode : "",
            })
        }) : [],
        primaryAccountBankName: values?.banking?.bankName,
        primaryBankAccountHolderFullName: values?.banking?.accountHolderName,
        primaryBankRoutingNumber: values?.banking?.depositRouting,
        swipedPercentage: values?.processingProfile?.acceptanceMethods?.swiped?.toString(),
        keyedPercentage: values?.processingProfile?.acceptanceMethods?.keyed?.toString(),
        motoPercentage: values?.processingProfile?.acceptanceMethods?.moto?.toString(),
        internetPercentage: values?.processingProfile?.acceptanceMethods?.internet?.toString(),
        avgTransactionSize: values?.processingProfile?.averageTicket?.toString(),
        high_ticket_volume: values?.processingProfile?.highestTicket?.toString(),
        expectedMonthlyCCVolume: values?.processingProfile?.averageMonthlyCardVolume?.toString(),
        isCurrentlyAcceptingCreditCards: values?.processingProfile?.currentlyAcceptingCards ? "yes" : "no",
        isSeasonal: values?.processingProfile?.seasonal ? "yes" : "no",
        seasonalFrom: values?.processingProfile?.seasonalFrom ? MONTHS.find((month) => month.value === values.processingProfile.seasonalFrom?.toString()) : undefined,
        seasonalTo: values?.processingProfile?.seasonalTo ? MONTHS.find((month) => month.value === values.processingProfile.seasonalTo?.toString()) : undefined
    }
};
