import Image from "next/image";
import { Dropdown, Navbar, Spinner } from "react-bootstrap";

import Breadcrumb from "@assets/header/breadcrumb.svg";
import Saved from "@assets/header/saved.svg";
import TextComponent from "@common/typography/TextComponent";
import { useSaveContext } from "@hooks/useAutoSave";
import useWizardContext from "@hooks/wizard/useWizardContext";
import useSidebarContext from "@hooks/useSidebarContext";
import { useAuth } from '@auth/useAuth';
import Avatar from '@assets/header/avatar.svg';

// Extracted From Header Since AuthContext will call out Authflow when rendered
export const LogoutDropDown = () => {
    const { logout } = useAuth()
    return (
        <Dropdown>
            <Dropdown.Toggle
                as="div"
                className="d-flex align-items-center cursor-pointer nav-dropdown text-secondary"
            >
                <div className="nav-profile-img ms-1 pt-1">
                    <Image src={Avatar} width="25" height="25" alt="Gravity" />

                    <span className="availability-status online" />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className="navbar-dropdown">
                <Dropdown.Item onClick={logout}>
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>)
}

const AppHeader = () => {
    const { completedSteps, isApplication, isSubmitted, steps  } = useWizardContext();
    const { isSaving } = useSaveContext();
    const { showSidebar, setShowSidebar } = useSidebarContext();

    return (
        <Navbar
            bg="white"
            sticky="top"
            className="border-silver border-bottom  d-block  p-2"
        >
            <div
                className={`justify-content-between align-items-center ${showSidebar ? "d-xs-block d-sm-flex" : "d-flex"}`}
            >
                <div className="d-flex">
                    <div className="mx-2">
                        <Image
                            src={Breadcrumb}
                            width="22"
                            height="25"
                            alt="Breadgrumb"
                            className="cursor-pointer"
                            onClick={() => setShowSidebar(!showSidebar)}
                        />
                    </div>
                    <div>
                        <Image
                            src="/gravity-emblem.png"
                            unoptimized
                            width="25"
                            height="25"
                            alt="Gravity"
                        />
                    </div>
                </div>
                <div>
                    {!isSubmitted && <div className="justify-content-center  mx-2 text-center">
                        {/* An opportunity has an extra step to complete  */}
                        <span>{Math.min(completedSteps, steps.length - 2)}</span> of <span>{isApplication ? steps.length - 3 : steps.length - 2}</span>{" "}
                        sections completed
                    </div>}
                </div>
                <div className="justify-content-end fw-bolder d-flex align-items-center">
                    <div className="me-2 d-flex align-items-center">
                        {!isSaving ? <>
                            <Image
                                src={Saved}
                                width="14"
                                height="14"
                                alt="Gravity"
                            />
                            <TextComponent fontSize="sm" className="ms-2">
                                Saved
                            </TextComponent>
                        </> : <TextComponent fontSize="sm" className="ms-2">
                            <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                            />{" "}
                            Saving
                        </TextComponent>}
                    </div>
                    <LogoutDropDown />
                </div>
            </div>
        </Navbar>
    );
};

export default AppHeader;
