import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

type AppContextType = {
    showSidebar: boolean;
    setShowSidebar: (val: boolean) => void;
};

export const SidebarContext = createContext<AppContextType>({
    showSidebar: true,
    setShowSidebar: () => {},
});

const useSidebarContext = () => {
    const context = useContext(SidebarContext);

    if (!context) {
        throw new Error(`useAppContext hook can be used only inside AppContext`);
    }

    return context;
};

export default useSidebarContext;

export const SidebarContextProvider = ({ children }: PropsWithChildren) => {
    const [showSidebar, setShowSidebar] = useState(true);

    useEffect(() => {
        if (window && window.innerWidth < 575) {
            setShowSidebar(false);
        }
    }, []);

    const context = useMemo(() => ({ showSidebar, setShowSidebar, }), [showSidebar]);

    return <SidebarContext.Provider value={context}>{children}</SidebarContext.Provider>
}
