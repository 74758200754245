import { IconProps } from "./type";

const RemoveCross = ({ fill = "white", className }: IconProps) => (
    <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M7 12.5C4.84375 12.5 2.875 11.375 1.79688 9.5C0.71875 7.64844 0.71875 5.375 1.79688 3.5C2.875 1.64844 4.84375 0.5 7 0.5C9.13281 0.5 11.1016 1.64844 12.1797 3.5C13.2578 5.375 13.2578 7.64844 12.1797 9.5C11.1016 11.375 9.13281 12.5 7 12.5ZM5.10156 4.60156C4.86719 4.83594 4.86719 5.1875 5.10156 5.39844L6.20312 6.5L5.10156 7.60156C4.86719 7.83594 4.86719 8.1875 5.10156 8.39844C5.3125 8.63281 5.66406 8.63281 5.875 8.39844L6.97656 7.29688L8.07812 8.39844C8.3125 8.63281 8.66406 8.63281 8.875 8.39844C9.10938 8.1875 9.10938 7.83594 8.875 7.60156L7.77344 6.5L8.875 5.39844C9.10938 5.1875 9.10938 4.83594 8.875 4.60156C8.66406 4.39062 8.3125 4.39062 8.07812 4.60156L6.97656 5.70312L5.875 4.60156C5.66406 4.39062 5.3125 4.39062 5.10156 4.60156Z"
            fill={fill}
        />
    </svg>
);

export default RemoveCross;

