"use client";

import { Button } from "@common";
import React from "react";
import Image from "next/image";
import InfoIcon from "@assets/info-icon.svg";
import Logo from "@assets/gravity-logo-svg.svg";
import TextComponent from "@common/typography/TextComponent";
import styled from "styled-components";
import { Navbar } from "react-bootstrap";
import { LogoutDropDown } from "@components/AppHeader";
import { currentYear } from "@utils/FormUtils";

export const StyledFooter = styled.div`
    height: 300px;
    clip-path: ellipse(100% 90% at bottom center);
    background-color: ${({ theme }) => theme.grey};
`;

const DEFAULT_MESSAGE = `Please try reloading the application. If you continue
receiving an error, please contact your Gravity sales
representative, or reach out to the Gravity support team for
further assistance.`;

const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
    const { message, name } = error;
    const [messageTitle, messageBody] = message.split('\n');

    return (
        <><Navbar
            bg="white"
            sticky="top"
            className="border-silver border-bottom  d-block  p-2"
        >
            <LogoutDropDown />
        </Navbar>
            <div className="d-flex flex-column justify-content-between min-vh-100">
                <div>
                    <div className="mt-5 container">
                        <div>
                            <TextComponent color="pewter" fontSize="xs">
                                MERCHANT APPLICATION
                            </TextComponent>
                            <TextComponent fontSize="title" fontWeight="800">
                                Error
                            </TextComponent>
                        </div>
                    </div>
                    <hr />
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <Image
                                src={InfoIcon}
                                width="22"
                                height="25"
                                alt="error-info"
                                className="cursor-pointer" />
                            <div className="ms-2" data-cy="error-title">
                                <TextComponent fontSize="lg" fontWeight="700">
                                    {messageTitle}
                                    {/* {errorCode ? (
                                     <TextComponent
                                     as="span"
                                     color="pewter"
                                     className="ms-2"
                                     fontWeight="600"
                                     >
                                     (Error Code: {errorCode})
                                     </TextComponent>
                                     ) : null} */}
                                </TextComponent>
                            </div>
                        </div>
                        <TextComponent className="mt-2" data-cy="error-message">
                            {messageBody ?? DEFAULT_MESSAGE}
                        </TextComponent>
                        <Button
                            data-cy="reload-button"
                            className="mt-3 mb-3"
                            variant="secondary"
                            onClick={reset}
                        >
                            Reload
                        </Button>
                        <hr style={{ width: 40 }} />
                        <TextComponent
                            className="mt-4"
                            fontSize="lg"
                            fontWeight="600"
                        >
                            Gravity Support Team
                        </TextComponent>
                        <div
                            className="d-flex mt-2 align-items-center"
                            data-cy="contact-support"
                        >
                            <Image
                                width={36}
                                height={36}
                                src="/gravity-emblem.png"
                                alt="gravity-emblem" />
                            <div className="ms-3">
                                <TextComponent
                                    fontSize="md"
                                    color="charcoal"
                                    fontWeight="600"
                                >
                                    Available 24/7
                                </TextComponent>
                                <TextComponent
                                    fontSize="md"
                                    color="charcoal"
                                    fontWeight="400"
                                >
                                    866-701-4700
                                </TextComponent>
                                <a
                                    href="mailto:techsupport@gravitypayments.com"
                                    className="text-decoration-none"
                                >
                                    <TextComponent
                                        fontSize="sm"
                                        fontWeight="400"
                                        color="secondary"
                                    >
                                        Email Support
                                    </TextComponent>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <StyledFooter className="d-flex flex-column justify-content-center align-items-center arial ">
                    <Image src={Logo} alt="logo" height={45} width={145} />
                    <TextComponent className="mt-3" color="pewter" fontSize="xss">
                        © {currentYear} Gravity Payments. All Rights Reserved.
                    </TextComponent>
                    <TextComponent className="mt-1" color="pewter" fontSize="xss">
                        Gravity Payments, Inc. is a registered ISO/MSP of Wells Fargo Bank, N.A., Concord, CA, and Synovus Bank, Columbus, GA
                    </TextComponent>
                </StyledFooter>
            </div>
        </>
    );
};

export default Error;
