import React, { useEffect, useState } from 'react';

const parse = (value: string) => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

// TODO(BL): refactor to ditch useEffect in favor of returning a callback. Somthing like:
//      return [
//          state,
//          newState => {
//          localStorage.setItem(key, JSON.stringify(newState));
//          setState(newState);
//          },
//      ];
const useLocalStorage = <S,>(key: string): [S, React.Dispatch<React.SetStateAction<S>>] => {
    const [state, setState] = useState<S>(typeof window !== 'undefined' ? parse(localStorage.getItem(key) as string) : null);

    // Saves to local storage the current form values. When using setState, we pass react-hook-form's getValues(). Local storage item is named either the application's ID or "default".
    useEffect(() => {
        if (state) {
            localStorage.setItem(key, JSON.stringify(state));
        }
    }, [key, state]);

    return [state, setState];
};

export default useLocalStorage;
