import { Row, Col, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';

import { FormLabel } from '@common';
import TextComponent from '@common/typography/TextComponent';
import FormRadioGroup from '@components/controller-components/FormRadioGroup';
import TextInputComponent from '@components/controller-components/TextInputComponent';
import useWizardContext from '@hooks/wizard/useWizardContext';
import FormSelectInput from '@components/controller-components/FormSelectInput';
import FileInputComponent from '@components/controller-components/FileInputComponent';
import CleaveInputGroup from '@components/controller-components/CleaveInputGroup';
import { useEffect } from 'react';
import CleaveInputComponent from '@components/controller-components/CleaveInput';
import type { DocumentFile } from '@common/inputs/FileInput';

export const Fields = [
    'daysToReturnOrCancel',
    'isGSDeliveryAfterTxnDay',
    'delivery_immediate_Percentage',
    'delivery_1_3_DaysPercentage',
    'delivery_4_7_DaysPercentage',
    'delivery_8_14_DaysPercentage',
    'delivery_15_30_DaysPercentage',
    'delivery_Over_30_DaysPercentage',
    'marketingWebsite',
    'marketingFile',
    'transactionCompletePeriod',
    'transactionCompletePeriodOther',
    'recurringMonthly',
    'billingPeriod',
    'entireCreditCardCollect',
    'PCICompliant',
    'doesCustomerSignContract',
    'contractFile',
    'howCustomerOrder',
    'vendorPurchaseAddress',
    'cnpAgreementRequired', // Move this to Context
    'serviceForCardHolder',
] as const;

export type Values = {
    daysToReturnOrCancel: number,
    isGSDeliveryAfterTxnDay?: 'yes'|'no',
    delivery_immediate_Percentage: number,
    delivery_1_3_DaysPercentage: number,
    delivery_4_7_DaysPercentage: number,
    delivery_8_14_DaysPercentage: number,
    delivery_15_30_DaysPercentage: number,
    delivery_Over_30_DaysPercentage: number,
    marketingWebsite?: string,
    marketingFile?: DocumentFile,
    transactionCompletePeriod?: { label: string, value: string },
    transactionCompletePeriodOther?: string,
    recurringMonthly?: 'yes'|'no',
    billingPeriod?: { label: string, value: string },
    entireCreditCardCollect?: 'yes'|'no',
    PCICompliant?: { label: string, value: string },
    doesCustomerSignContract?: 'yes'|'no',
    contractFile?: DocumentFile,
    howCustomerOrder?: string,
    vendorPurchaseAddress?: string,
    cnpAgreementRequired?: 'yes'|'no',
    serviceForCardHolder?: string,
};

const getAppentPercentage = (variant = 'secondary') => (
    <InputGroup.Text className={`bg-${variant} px-3 text-white border-${variant}`}>
        %
    </InputGroup.Text>
);

const orderLabel = () => (
    <div>
        How does the customer order the product/service?
        <div>(Please provide the process from customer order to shipment)</div>
    </div>
);

export const timePeriodOptions = [
    { label: 'Immediately', value: 'Immediately' },
    { label: '1-5 days', value: '1-5 days' },
    { label: '5-10 days', value: '5-10 days' },
    { label: '10-15 days', value: '10-15 days' },
    { label: '15-30 days', value: '15-30 days' },
    { label: 'Other', value: 'Other' },
];

export const BillingDuration = [
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Bi-annual', label: 'Bi-annual' },
    { value: 'Annual', label: 'Annual' },
];

const ProcessingPage = () => {
    const { form } = useWizardContext();

    const { control, resetField, watch, setValue, trigger } = form;

    const isRecurringBill = watch('recurringMonthly') === 'yes';
    const isDBCollectingCCNumbers = watch('entireCreditCardCollect') === 'yes';
    const isOrderDelivryAfterTxn = watch('isGSDeliveryAfterTxnDay') === 'yes';
    const doesCustomerSignContract = watch('doesCustomerSignContract') === 'yes';
    const isCNPRequired = watch('cnpAgreementRequired') === 'yes';
    const marketingSite = watch('marketingWebsite');
    const marketingFile = watch('marketingFile');
    const otherCreditAuthDuration = watch('transactionCompletePeriod')?.value === 'Other';
    const deliveryPercentages = {
        DELIVERY_IMMEDIATE: watch('delivery_immediate_Percentage') || 0,
        DELIVERY_1_3_DAYS: watch('delivery_1_3_DaysPercentage') || 0,
        DELIVERY_4_7_DAYS: watch('delivery_4_7_DaysPercentage') || 0,
        DELIVERY_8_14_DAYS: watch('delivery_8_14_DaysPercentage') || 0,
        DELIVERY_15_30_DAYS: watch('delivery_15_30_DaysPercentage') || 0,
        DELIVERY_OVER_30_DAYS: watch('delivery_Over_30_DaysPercentage') || 0,
    }

    const showDeliveryPercentagesError = Object.values(deliveryPercentages).map(Number).reduce((acc, currentValue) => acc + currentValue, 0) !== 100;

    useEffect(() => {
        if (!isRecurringBill) {
            resetField('billingPeriod');
        }
        if (!isDBCollectingCCNumbers) {
            resetField('PCICompliant');
        }
        if (!doesCustomerSignContract) {
            resetField('contractFile');
        }
    }, [isRecurringBill, isDBCollectingCCNumbers, doesCustomerSignContract, resetField]);

    useEffect(() => {
        // require (MARKETING_FILE OR MARKETING_WEBSITE) not both
        if (marketingSite?.trim()) {
            if (!marketingSite?.includes('http')) {
                if (yup?.string()?.url()?.optional().isValidSync(`https://${marketingSite}`))
                    setValue('marketingWebsite', `https://${marketingSite}`, { shouldValidate: true });
            }
            trigger('marketingFile');
        }
        else if (marketingFile) {
            trigger('marketingWebsite');
        }
    }, [setValue, trigger, marketingSite, marketingFile]);

    const getPercentageInputComponent = (name: string, label: string) => (
        <CleaveInputGroup
            control={control}
            name={name}
            label={label}
            options={{
                numeral: true,
                numeralPositiveOnly: true,
                numeralDecimalScale: 0,
                numeralThousandsGroupStyle: 'none',
                numeralIntegerScale: 3,
                stripLeadingZeroes: true,
            }}
            placeholder="0"
            inputMode='numeric'
            append={getAppentPercentage()}
        />
    );

    return (
        <div className="p-3">
            <Row>
                <Col data-cy='daysToReturnOrCancel'>
                    <CleaveInputComponent
                        options={{
                            numeral: true,
                            numeralPositiveOnly: true,
                            numeralDecimalScale: 0,
                            numeralThousandsGroupStyle: 'none',
                            numeralIntegerScale: 13,
                            stripLeadingZeroes: true,
                        }}
                        inputMode="numeric"
                        control={control}
                        name='daysToReturnOrCancel'
                        label="How many days from purchase does a customer have to return and/or cancel their order?"
                    />
                </Col>
            </Row>
            <Row className="my-4">
                <Col md={9} data-cy='isGSDeliveryAfterTxnDay'>
                    <TextComponent fontWeight="500">
                        Are any of your products/service provided to customers one or more days
                        after the credit card transaction is completed?
                    </TextComponent>

                    <FormRadioGroup
                        name='isGSDeliveryAfterTxnDay'
                        defaultValue="no"
                        className="mt-3"
                        control={control}
                        options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                        ]}
                    />
                </Col>
            </Row>
            {isOrderDelivryAfterTxn && <>
                <TextComponent className="mt-3">
                    What is the time frame from when payment is collected to when the
                    product/service is provided?
                </TextComponent>
                <Row className="my-1">
                    <Col md={3} data-cy='delivery_immediate_Percentage'>
                        {getPercentageInputComponent('delivery_immediate_Percentage', 'Immediately')}
                    </Col>
                    <Col md={3} data-cy='delivery_1_3_DaysPercentage'>
                        {getPercentageInputComponent('delivery_1_3_DaysPercentage', '1-3 days')}
                    </Col>
                    <Col md={3} data-cy='delivery_4_7_DaysPercentage'>
                        {getPercentageInputComponent('delivery_4_7_DaysPercentage', '4-7 days')}
                    </Col>
                </Row>
                <Row className="my-1">
                    <Col md={3} data-cy='delivery_8_14_DaysPercentage'>
                        {getPercentageInputComponent('delivery_8_14_DaysPercentage', '8-14 days')}
                    </Col>
                    <Col md={3} data-cy='delivery_15_30_DaysPercentage'>
                        {getPercentageInputComponent('delivery_15_30_DaysPercentage', '15-30 days')}
                    </Col>
                    <Col md={3} data-cy='delivery_Over_30_DaysPercentage'>
                        {getPercentageInputComponent('delivery_Over_30_DaysPercentage', 'over 30 days')}
                    </Col>
                </Row>
                {showDeliveryPercentagesError && <div className="text-danger mt-2">
                    Delivery Percentages Total Should Be 100%
                </div>}
            </>
            }
            {isCNPRequired && <>
                <Row className="my-4">
                    <Col md={9}>
                        <FormLabel>How will product be advertised or promoted?</FormLabel>
                        <TextComponent fontSize="xs" fontWeight="400">
                            *If advertising on the internet, provide website address or select upload
                            button to submit sample(s) of product brochure, promotional materials,
                            product catalogue, etc.
                        </TextComponent>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col md={12} data-cy='marketingWebsite'>
                        <TextInputComponent
                            control={control}
                            name='marketingWebsite'
                            label="Website Address (or link to marketing collateral)"
                        />
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col data-cy='marketingFile'>
                        <FileInputComponent
                            control={control}
                            name='marketingFile'
                            placeholder="Upload Files here"
                        />
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col md={6} data-cy='transactionCompletePeriod'>
                        <FormSelectInput
                            control={control}
                            placeholder="Make a Selection"
                            name='transactionCompletePeriod'
                            label="How long after credit card authorization is the transaction completed?"
                            options={timePeriodOptions}
                        />
                        {otherCreditAuthDuration && <Row className="mt-4">
                            <Col md={12} data-cy='transactionCompletePeriodOther'>
                                <TextInputComponent
                                    control={control}
                                    name='transactionCompletePeriodOther'
                                    placeholder="Enter length of credit card authorization"
                                />
                            </Col>
                </Row>}
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col
                        className="d-flex align-items-center justify-content-between flex-wrap"
                        data-cy='recurringMonthly'
                    >
                        <TextComponent fontWeight="500">
                            Do you perform recurring billing?
                        </TextComponent>
                        <FormRadioGroup
                            name='recurringMonthly'
                            defaultValue="no"
                            control={control}
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                            ]}
                        />
                    </Col>
                </Row>
                {isRecurringBill && <Row className="my-4">
                    {/* FIXME(LY) Default value doesn't get saved until merchant manually clicks it */}
                    <Col md={6} data-cy='billingPeriod'>
                        <FormSelectInput
                            control={control}
                            placeholder="Make a Selection"
                            name='billingPeriod'
                            label="How often does billing occur?"
                            options={BillingDuration}
                        />
                    </Col>
                </Row>}
                <Row className="my-4">
                    <Col
                        className="d-flex align-items-center justify-content-between flex-wrap"
                        data-cy='entireCreditCardCollect'
                    >
                        <TextComponent fontWeight="500">
                            Does your database collect entire credit card numbers?
                        </TextComponent>
                        <FormRadioGroup
                            name='entireCreditCardCollect'
                            defaultValue="no"
                            control={control}
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                            ]}
                        />
                    </Col>
                </Row>
                {isDBCollectingCCNumbers && <Row className="my-4">
                    <Col md={6} data-cy='PCICompliant'>
                        <FormSelectInput
                            control={control}
                            placeholder="Make a Selection"
                            name='PCICompliant'
                            label="Are you PCI Compliant?"
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                            ]}
                        />
                    </Col>
                </Row>}
                <Row className="my-4">
                    <Col
                        className="d-flex align-items-center justify-content-between flex-wrap"
                        data-cy='doesCustomerSignContract'
                    >
                        <TextComponent fontWeight="500">
                            Does the customer sign a contract specifying terms and conditions?
                        </TextComponent>

                        <FormRadioGroup
                            name='doesCustomerSignContract'
                            defaultValue="no"
                            control={control}
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                            ]}
                        />
                    </Col>
                </Row>
                {doesCustomerSignContract && <Row className="my-4">
                    <Col data-cy='contractFile'>
                        <FileInputComponent
                            control={control}
                            name='contractFile'
                            placeholder="Upload Files here"
                        />
                    </Col>
                </Row>}
                <Row className="my-4">
                    <Col data-cy='howCustomerOrder'>
                        <TextInputComponent
                            control={control}
                            rows={2}
                            isTextArea
                            className="w-100"
                            name='howCustomerOrder'
                            label={orderLabel()}
                        />
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col data-cy='vendorPurchaseAddress'>
                        <TextInputComponent
                            control={control}
                            rows={2}
                            isTextArea
                            className="w-100"
                            name='vendorPurchaseAddress'
                            label="List name(s) and address(es) of vendor from which the product is purchased?"
                        />
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col data-cy='serviceForCardHolder'>
                        <TextInputComponent
                            control={control}
                            rows={2}
                            className="w-100"
                            isTextArea
                            name='serviceForCardHolder'
                            label="What product(s) or service(s) does your organization provide the cardholder? (Please be specific)"
                        />
                    </Col>
                </Row></>}
            <hr />
        </div>
    );
};

export default ProcessingPage;
