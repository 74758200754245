import { IconProps } from './type';

const CaretDown = ({ fill = '#107AB0', className }: IconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.98438 9.98438H17.0156L12 15L6.98438 9.98438Z" fill={fill} />
        </svg>
    );
};

export default CaretDown;
