/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormLabel } from '@common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import SelectInput from '@common/select/SelectInput';
import useAutoSave from '@hooks/useAutoSave';
import FormError from '@components/form/FormErrorComponent';

export type Option = {
    label: string;
    value: string;
    [key: string]: any;
};

export type FormSelectInputProps<T extends Option> = {
    control: Control<any>;
    name: string;
    label?: string | React.ReactNode;
    options?: readonly T[];
    className?: string;
    placeholder?: string;
    rules?: RegisterOptions;
    onChange?: (option: T) => void;
    showError?: boolean;
    autoComplete?: string;
    disabled?: boolean;
    required?: boolean;
};

const FormSelectInput = <T extends Option>({
    control,
    name = '',
    rules = undefined,
    placeholder = '',
    label = '',
    options = [],
    className = '',
    onChange = () => {},
    showError = true,
    autoComplete = undefined,
    disabled = false,
    required = true,
}: FormSelectInputProps<T>) => {
    const { handleSave } = useAutoSave();

    const handleChange = async (option: Option, fieldOnChange: (...event: any[]) => void) => {
        if (option.value) {
            fieldOnChange(option);
            onChange(option as T);
            handleSave({ name, value: option.value });
        }
    };

    return (
        <Controller
            name={name}
            key={name}
            control={control}
            rules={rules}
            render={({ field: { onChange: fieldOnChange, value }, fieldState: { error } }) => (
                <>
                    {label ? <FormLabel>{label}</FormLabel> : null}
                    <SelectInput
                        onChange={val => handleChange(val as Option, fieldOnChange)}
                        className={required && error
                            ? className
                                ? className.concat(' required-with-error')
                                : 'required-with-error'
                            : className}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        options={options}
                        {...autoComplete && { autoComplete }}
                        isDisabled={disabled}
                    />
                    {showError && error?.message ? <FormError errorMsg={error.message} /> : null}
                </>
            )}
        />
    );
};

export default FormSelectInput;
