import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import DocumentBadge from '@components/icons/DocumentBadge';
import RemoveCross from '@components/icons/RemoveCross';
import TextComponent from '@common/typography/TextComponent';
import Camera from '@components/icons/Camera';
import { Button } from '@common';
import Image from 'next/image';
import type { DocumentFile } from './FileInput';

const ImageContainer = styled.div`
    height: 138px;
    width: 220px;
    border: 1px dashed #c9ced0;
    border-radius: 8px;
    align-content: center;
    position: relative;
    text-align: center;
    .divide-border {
        border-right: 1px dashed #c9ced0;
    }
    .loader {
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
    }
    @media (max-width: 576px) {
        width: 336px;
        height: 212px;
    }
`;

type PhotoIDProps = {
    handleClick: () => void;
    handleRemove: () => void;
    handleTakePicture: () => void;
    value?: DocumentFile;
    selectedFile?: string;
    loading: boolean;
    className?: string;
};

const FileInputPhotoID = ({
    handleClick,
    handleRemove,
    handleTakePicture,
    value = undefined,
    loading,
    selectedFile = undefined,
    className = '',
}: PhotoIDProps) => {
    const renderUploadLabel = () => (
        <>
            <DocumentBadge />
            <TextComponent color='secondary' fontWeight='500' className='mt-2'>
                Upload Photo ID
            </TextComponent>
        </>
    );
    return (
        <>
            <div className='mt-2'>
                <ImageContainer>
                    {loading ? (
                        <Spinner
                            animation='border'
                            variant='secondary'
                            className='loader'
                        />
                    ) : null}
                    {!selectedFile && !value ? (
                        <>
                            <div
                                className={`h-100 align-content-center d-sm-block d-none ${className}`}
                                tabIndex={0}
                                onClick={handleClick}
                                role='button'
                                onKeyUp={handleClick}
                            >
                                {renderUploadLabel()}
                            </div>
                            <div className='h-100 align-content-center d-block d-sm-none'>
                                <div className='d-flex h-100'>
                                    <div
                                        className='h-100 w-50 align-content-center p-4 divide-border'
                                        tabIndex={0}
                                        onClick={handleClick}
                                        role='button'
                                        onKeyUp={handleClick}
                                    >
                                        {renderUploadLabel()}
                                    </div>
                                    <div
                                        className='h-100 w-50 align-content-center'
                                        tabIndex={0}
                                        onClick={handleTakePicture}
                                        role='button'
                                        onKeyUp={handleTakePicture}
                                    >
                                        <Camera />
                                        <TextComponent
                                            color='secondary'
                                            fontWeight='500'
                                            className='mt-2'
                                        >
                                            Take Picture
                                        </TextComponent>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {selectedFile && value?.contentType?.includes('image') ? (
                        <Image
                            src={selectedFile}
                            alt='photoId'
                            height='138'
                            width='220'
                            className='h-100 w-100 p-2 object-fit-contain'
                        />
                    ) : (
                        value?.contentType && (
                            <TextComponent
                                color='primary'
                                fontWeight='600'
                                className='p-2'
                            >
                                {value?.name}
                            </TextComponent>
                        )
                    )}
                </ImageContainer>
            </div>
            {value ? (
                <Button
                    variant='secondary'
                    className='mt-2'
                    onClick={handleRemove}
                >
                    <TextComponent fontSize='xs' color='white'>
                        <RemoveCross className='me-1' />
                        Remove Photo ID
                    </TextComponent>
                </Button>
            ) : null}
        </>
    );
};
export default FileInputPhotoID;
