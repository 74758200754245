import styled from "styled-components";
import { Form, FormCheckProps } from "react-bootstrap";

const InputRadio = styled((props: FormCheckProps ) => <Form.Check type="radio" {...props} />)`
    .form-check-input:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23107AB0'/%3e%3c/svg%3e");
        background-color: white;
        border: 1px solid ${(props) => props.theme.silver} !important;
    }
    .form-check-input {
        border: 1px solid ${(props) => props.theme.silver} !important;
    }
    &.required-with-error input {
        background-color: ${(props) => props.theme?.requiredWithError.backgroundColor} !important;
        border: ${(props) => props.theme?.requiredWithError.border} !important;
    }
`;

export default InputRadio;
