import React from 'react';
import { FormLabel, CleaveInput } from '@common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { InputGroup } from 'react-bootstrap';
import useAutoSave from '@hooks/useAutoSave';
import { CleaveOptions } from 'cleave.js/options';
import FormError from '@components/form/FormErrorComponent';

export type CleaveInputGroupProps = {
    control: Control<any>;
    name: string;
    label?: string | React.ReactNode;
    options: CleaveOptions;
    type?: string;
    defaultValue?: string;
    returnRawValue?: boolean;
    placeholder?: string;
    prepend?: string | React.ReactNode;
    append?: string | React.ReactNode;
    rules?: RegisterOptions;
    showError?: boolean;
    disabled?: boolean;
    inputMode?: 'search' | 'text' | 'email' | 'none' | 'tel' | 'url' | 'numeric' | 'decimal';
    required?: boolean;

};

const CleaveInputGroup = ({
    control,
    name = '',
    rules = undefined,
    placeholder = '',
    returnRawValue = true,
    options,
    type = 'text',
    defaultValue = '',
    label = '',
    prepend = null,
    append = null,
    disabled = false,
    inputMode = 'text',
    showError = true,
    required = true,
}: CleaveInputGroupProps) => {
    const { handleSave } = useAutoSave();

    return (
        <Controller
            name={name}
            key={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    {label ? <FormLabel>{label}</FormLabel> : null}
                    <InputGroup>
                        {prepend || null}
                        <CleaveInput
                            disabled={disabled}
                            options={options}
                            onBlur={({ currentTarget }) => handleSave({ name, value: currentTarget.value })}
                            onChange={(e) =>
                                onChange(
                                    returnRawValue ? e.target?.rawValue : e?.target?.value || ''
                                )
                            }
                            name={name}
                            className={required && error ? 'required-with-error' : ''}
                            placeholder={placeholder}
                            type={type}
                            value={value}
                            inputMode={inputMode}
                        />
                        {append || null}
                    </InputGroup>
                    {showError && error?.message && <FormError errorMsg={error.message} />}
                </>
            )}
        />
    );
};

export default CleaveInputGroup;
