import { IconProps } from "./type";

const DocumentBadge = ({ fill = "#107AB0", className }: IconProps) => (
    <svg
        width="27"
        height="22"
        viewBox="0 0 27 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M0 3.5C0 1.85938 1.3125 0.5 3 0.5H24C25.6406 0.5 27 1.85938 27 3.5H0ZM0 5H27V18.5C27 20.1875 25.6406 21.5 24 21.5H3C1.3125 21.5 0 20.1875 0 18.5V5ZM3 18.0312C3 18.3125 3.1875 18.5 3.46875 18.5H12.9844C13.2656 18.5 13.5 18.3125 13.5 18.0312C13.5 16.625 12.375 15.5 10.9688 15.5H5.48438C4.07812 15.5 3 16.625 3 18.0312ZM8.25 8C7.17188 8 6.1875 8.60938 5.625 9.5C5.10938 10.4375 5.10938 11.6094 5.625 12.5C6.1875 13.4375 7.17188 14 8.25 14C9.28125 14 10.2656 13.4375 10.8281 12.5C11.3438 11.6094 11.3438 10.4375 10.8281 9.5C10.2656 8.60938 9.28125 8 8.25 8ZM16.5 8.75C16.5 9.17188 16.8281 9.5 17.25 9.5H23.25C23.625 9.5 24 9.17188 24 8.75C24 8.375 23.625 8 23.25 8H17.25C16.8281 8 16.5 8.375 16.5 8.75ZM16.5 11.75C16.5 12.1719 16.8281 12.5 17.25 12.5H23.25C23.625 12.5 24 12.1719 24 11.75C24 11.375 23.625 11 23.25 11H17.25C16.8281 11 16.5 11.375 16.5 11.75ZM16.5 14.75C16.5 15.1719 16.8281 15.5 17.25 15.5H23.25C23.625 15.5 24 15.1719 24 14.75C24 14.375 23.625 14 23.25 14H17.25C16.8281 14 16.5 14.375 16.5 14.75Z"
            fill={fill}
        />
    </svg>
);

export default DocumentBadge;
