import React from 'react';
import { TextComponent } from '@common/typography/TextComponent';

interface FormErrorComponentProps {
    errorMsg: string;
}

const FormError = (props: FormErrorComponentProps) => {
    const { errorMsg } = props;

    if (!errorMsg) {
        return <div />;
    }

    return (
        <TextComponent className="my-2" color="danger" fontSize="sm">
            {errorMsg}
        </TextComponent>
    );
};

export default FormError;
