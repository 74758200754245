import { FetchOpportunityResponse } from "@app/api/opportunity/[id]/route";
import { BillingDuration, timePeriodOptions } from "@components/page-steps/ProcessingPage";
import {BankFields, BusinessFields, EquipmentSetUpFields, ProcessingFields} from "@hooks/wizard/types";
import BankAccountDDATypes from "@utils/static-options/bankAccountDDATypes";
import { LegalEntityTypeOptions } from "@utils/static-options/entityType";
import OwnerTypes from "@utils/static-options/ownerTypes";
import US_STATES from "@utils/static-options/state";
import { IFile__c } from '@pppinc/salesforce/lib/interfaces/sobject';
import type { AllFields } from "@hooks/wizard/types";
import type { DocumentFile } from "@common/inputs/FileInput";

/**
 * Map values fetched (from local storage or MAE API) to form fields
 * @param fetchOpValues values fetched from MAE API
 * @param localStorageValues values fetched from local storage
 * @param priority which values to prioritize: local vs oppty (MAE API)
 * @returns object which represents a merge of localStorageValues and formData
 */
export const mapAPIValuesToForm = (fetchOpValues: FetchOpportunityResponse, localStorageValues: any, priority: 'local' | 'api' = 'local') => {
    const getOpptyFields = (fetchValue: any, localValue: any) => {
        if (priority === 'local') return localValue ?? fetchValue
        return fetchValue ?? localValue
    }

    const formData = {
        targetDate: getOpptyFields(fetchOpValues?.closeDate, localStorageValues?.targetDate),
        [BusinessFields.LEGALNAME]: getOpptyFields(fetchOpValues?.businessDetails?.legalName, localStorageValues?.[BusinessFields.LEGALNAME]),
        [BusinessFields.B_ADD1]: getOpptyFields(fetchOpValues?.businessDetails?.dbaAddress?.dbaAddrOne, localStorageValues?.[BusinessFields.B_ADD1]),
        [BusinessFields.B_ADD2]: getOpptyFields(fetchOpValues?.businessDetails?.dbaAddress?.dbaAddrTwo, localStorageValues?.[BusinessFields.B_ADD2]),
        [BusinessFields.B_CITY]: getOpptyFields(fetchOpValues?.businessDetails?.dbaAddress?.dbaCity, localStorageValues?.[BusinessFields.B_CITY]),
        [BusinessFields.B_STATE]: getOpptyFields(US_STATES?.find(option => (option.value === fetchOpValues?.businessDetails?.dbaAddress?.dbaState)), localStorageValues?.[BusinessFields.B_STATE]),
        [BusinessFields.B_ZIPCODE]: getOpptyFields(fetchOpValues.businessDetails.dbaAddress.dbaPostalCode, localStorageValues?.[BusinessFields.B_ZIPCODE]),
        [BusinessFields.B_EMAIL]: getOpptyFields(fetchOpValues?.businessDetails?.dbaEmail, localStorageValues?.[BusinessFields.B_EMAIL]),
        [BusinessFields.IS_LEGAL_SAME_AS_PYSICAL]: getOpptyFields(fetchOpValues.businessDetails?.dbaAddress?.dbaAddrOne && (
            fetchOpValues.businessDetails.dbaAddress.dbaAddrOne === fetchOpValues.businessDetails?.legalAddress?.legalAddrOne
            && fetchOpValues.businessDetails.dbaAddress.dbaAddrOne.trim().length > 0
        ) ? 'yes' : 'no', localStorageValues?.[BusinessFields.IS_LEGAL_SAME_AS_PYSICAL]),
        [BusinessFields.L_ADD1]: getOpptyFields(fetchOpValues.businessDetails?.legalAddress?.legalAddrOne, localStorageValues?.[BusinessFields.L_ADD1]),
        [BusinessFields.L_ADD2]: getOpptyFields(fetchOpValues.businessDetails?.legalAddress?.legalAddrTwo, localStorageValues?.[BusinessFields.L_ADD2]),
        [BusinessFields.L_CITY]: getOpptyFields(fetchOpValues.businessDetails?.legalAddress.legalCity, localStorageValues?.[BusinessFields.L_CITY]),
        [BusinessFields.L_STATE]: getOpptyFields(US_STATES.find(option => option.value === fetchOpValues.businessDetails?.legalAddress?.legalState), localStorageValues?.[BusinessFields.L_STATE]),
        [BusinessFields.L_ZIPCODE]: getOpptyFields(fetchOpValues.businessDetails?.legalAddress?.legalPostalCode, localStorageValues?.[BusinessFields.L_ZIPCODE]),
        [BusinessFields.B_PHONE]: getOpptyFields(fetchOpValues?.businessDetails?.businessPhone, localStorageValues?.[BusinessFields.B_PHONE]),
        [BusinessFields.B_TAX_METHOD]: getOpptyFields(fetchOpValues?.businessDetails?.tinType, localStorageValues?.[BusinessFields.B_TAX_METHOD]),
        [BusinessFields.ENTITY_TYPE]: getOpptyFields(LegalEntityTypeOptions.find(option => option.value === fetchOpValues?.businessDetails?.entityType), localStorageValues?.[BusinessFields.ENTITY_TYPE]),
        [BusinessFields.TIN]: getOpptyFields(fetchOpValues?.businessDetails?.tin, localStorageValues?.[BusinessFields.TIN]),
        [BusinessFields.DOE]: getOpptyFields(fetchOpValues.businessDetails.businessStartDate, localStorageValues?.[BusinessFields.DOE]),
        [BusinessFields.B_WEBSITE]: getOpptyFields(fetchOpValues?.businessDetails?.websiteUrl, localStorageValues?.[BusinessFields.B_WEBSITE]),
        [ProcessingFields.DAYS_TO_RETURN_CANCEL]: getOpptyFields(fetchOpValues.processingDetails?.refundCancelPolicyInDays, localStorageValues?.[ProcessingFields.DAYS_TO_RETURN_CANCEL]),
        [ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY]: getOpptyFields(fetchOpValues.processingDetails.txnCompleteBeforeShipping, localStorageValues?.[ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY]),
        [ProcessingFields.MARKETING_WEBSITE]: getOpptyFields(fetchOpValues.processingDetails?.howProductAdvertised, localStorageValues?.[ProcessingFields.MARKETING_WEBSITE]),
        [ProcessingFields.DELIVERY_IMMEDIATE]: getOpptyFields(fetchOpValues.processingDetails?.delivery?.delayImmediate, localStorageValues?.[ProcessingFields.DELIVERY_IMMEDIATE]),
        [ProcessingFields.DELIVERY_1_3_DAYS]: getOpptyFields(fetchOpValues.processingDetails?.delivery?.delay_1_3_days, localStorageValues?.[ProcessingFields.DELIVERY_1_3_DAYS]),
        [ProcessingFields.DELIVERY_4_7_DAYS]: getOpptyFields(fetchOpValues.processingDetails?.delivery?.delay_4_7_days, localStorageValues?.[ProcessingFields.DELIVERY_4_7_DAYS]),
        [ProcessingFields.DELIVERY_8_14_DAYS]: getOpptyFields(fetchOpValues.processingDetails?.delivery?.delay_8_14_days, localStorageValues?.[ProcessingFields.DELIVERY_8_14_DAYS]),
        [ProcessingFields.DELIVERY_15_30_DAYS]: getOpptyFields(fetchOpValues.processingDetails?.delivery?.delay_15_30_days, localStorageValues?.[ProcessingFields.DELIVERY_15_30_DAYS]),
        [ProcessingFields.DELIVERY_OVER_30_DAYS]: getOpptyFields(fetchOpValues.processingDetails?.delivery?.delay_over_30_days, localStorageValues?.[ProcessingFields.DELIVERY_OVER_30_DAYS]),
        [ProcessingFields.RECURRING_BILLING]: getOpptyFields(fetchOpValues?.processingDetails?.billingPeriod && fetchOpValues?.processingDetails?.billingPeriod?.trim()?.length > 0 ? 'yes' : 'no', localStorageValues?.[ProcessingFields.RECURRING_BILLING]),
        [ProcessingFields.BILLING_PERIOD]: getOpptyFields(BillingDuration.find(option => option.value === fetchOpValues?.processingDetails?.billingPeriod), localStorageValues?.[ProcessingFields.BILLING_PERIOD]),
        [ProcessingFields.DB_COLLECTING_CC_NUMBERS]: getOpptyFields(fetchOpValues.processingDetails.doesDbCollectCardNumbers ? 'yes' : 'no', localStorageValues?.[ProcessingFields.DB_COLLECTING_CC_NUMBERS]),
        [ProcessingFields.PCI_COMPLIANT]: getOpptyFields([{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }].find(elem => elem.value === (fetchOpValues.processingDetails?.isPciCompliant ? 'yes' : 'no')), localStorageValues?.[ProcessingFields.PCI_COMPLIANT]),
        [ProcessingFields.DOES_CUS_SIGN_CONTRACT]: getOpptyFields(fetchOpValues.processingDetails?.doesCustomerSignContract ? 'yes' : 'no', localStorageValues?.[ProcessingFields.DOES_CUS_SIGN_CONTRACT]),
        [ProcessingFields.HOW_CUSTOMER_ORDER]: getOpptyFields(fetchOpValues.processingDetails?.howDoesCustomerOrder, localStorageValues?.[ProcessingFields.HOW_CUSTOMER_ORDER]),
        [ProcessingFields.VENDOR_PUR_ADDRESS]: getOpptyFields(fetchOpValues.processingDetails?.vendorNamesAndAddresses, localStorageValues?.[ProcessingFields.VENDOR_PUR_ADDRESS]),
        [ProcessingFields.SERVICE_FOR_CC_HOLDERS]: getOpptyFields(fetchOpValues.processingDetails?.productsOrServicesProvided, localStorageValues?.[ProcessingFields.SERVICE_FOR_CC_HOLDERS]),
        [ProcessingFields.TXN_COMPLETE_PERIOD]: getOpptyFields(!fetchOpValues.processingDetails.ccAuthDuration ? '' : timePeriodOptions.find(option => option.value === fetchOpValues.processingDetails.ccAuthDuration) ?? timePeriodOptions.find(option => option.value === 'Other'), localStorageValues?.[ProcessingFields.TXN_COMPLETE_PERIOD]),
        [ProcessingFields.TXN_COMPLETE_PERIOD_OTHER]: getOpptyFields(!fetchOpValues.processingDetails.ccAuthDuration || timePeriodOptions.find(option => option.value === fetchOpValues.processingDetails.ccAuthDuration) ? '' : fetchOpValues.processingDetails.ccAuthDuration, localStorageValues?.[ProcessingFields.TXN_COMPLETE_PERIOD_OTHER]),
        'people': getOpptyFields(fetchOpValues.people?.length && fetchOpValues.people.filter(person => person?.lastName).map((person, i) => ({
            Addr1: person.address.line1,
            Addr2: person.address.line2,
            City: person.address.city,
            ContactNumber: person.phone,
            DateOfBirth: person.dob,
            Email: person.email,
            FirstName: person.firstName,
            LastName: person.lastName,
            PercentOwnership: person.percentOwnership,
            SSN: person.ssn,
            State: US_STATES.find(option => option.value === person.address.state),
            Title: OwnerTypes.find(option => option.value === person.title),
            ZipCode: person.address.postalCode,
            isPrimaryLocSameAsBusiness: person?.hasBusinessAddress === 'yes' || (person?.address.line1 === fetchOpValues?.businessDetails.dbaAddress.dbaAddrOne)
                && (person?.address.line2 === fetchOpValues?.businessDetails.dbaAddress.dbaAddrTwo)
                && (fetchOpValues?.businessDetails.dbaAddress.dbaAddrOne.trim().length > 0) ? 'yes' : 'no',
            ...(i === 0 && person.percentOwnership > 0 && fetchOpValues?.businessDetails?.entityType !== 'Sole Proprietorship' ? { IsOwner: 'yes' } : {}),
            PhotoID: person.photoId,
        })), localStorageValues?.people),
        [BankFields.P_BANK_ROUTING_NUMBER]: getOpptyFields(fetchOpValues.bankDetails.primaryRoutingNumber, localStorageValues?.[BankFields.P_BANK_ROUTING_NUMBER]),
        [BankFields.P_BANK_ACCOUNT_NUMBER]: getOpptyFields(fetchOpValues.bankDetails.primaryBankAccountNumber, localStorageValues?.[BankFields.P_BANK_ACCOUNT_NUMBER]),
        primaryBankIsChase: fetchOpValues.bankDetails.primaryBankIsChase,
        primaryBankValidatedByPlaid: fetchOpValues.bankDetails.primaryBankValidatedByPlaid,
        primaryAccountDDAType: getOpptyFields(BankAccountDDATypes.find(option => option.value === fetchOpValues.bankDetails.bankAccountType) || '', localStorageValues?.primaryAccountDDAType),
        [BankFields.USE_ALTERNATIVE_ACCOUNT]: getOpptyFields(fetchOpValues.bankDetails.useAlternativeAccount ? 'yes' : 'no', localStorageValues?.[BankFields.USE_ALTERNATIVE_ACCOUNT]),
        [BankFields.ALT_BANK_ROUTING_NUMBER]: getOpptyFields(fetchOpValues.bankDetails.altRoutingNumber, localStorageValues?.[BankFields.ALT_BANK_ROUTING_NUMBER]),
        [BankFields.ALT_BANK_ACCOUNT_NUMBER]: getOpptyFields(fetchOpValues.bankDetails.altBankAccountNumber, localStorageValues?.[BankFields.ALT_BANK_ACCOUNT_NUMBER]),
        altBankIsChase: fetchOpValues.bankDetails.altBankIsChase,
        altBankValidatedByPlaid: fetchOpValues.bankDetails.altBankValidatedByPlaid,
        altAccountDDAType: getOpptyFields(BankAccountDDATypes.find(option => option.value === fetchOpValues.bankDetails.altBankAccountType) || '', localStorageValues?.altAccountDDAType),
        [BankFields.WAIVE_PERSONAL_GUARANTEE]: getOpptyFields(fetchOpValues.bankDetails.isPersonalGuarantee === 'no' ? 'yes' : fetchOpValues.bankDetails.isPersonalGuarantee === 'yes' ? 'no' : '', localStorageValues?.[BankFields.WAIVE_PERSONAL_GUARANTEE]),
        cnpAgreementRequired: fetchOpValues.cnpAgreementRequired ? 'yes' : 'no',
        internetPercentage: fetchOpValues.internetPercentage,
        isUnsolicited: fetchOpValues.processingDetails.isUnsolicited,
        opportunityStage: fetchOpValues.opportunityStage,
        dbaName: fetchOpValues.dba,
        opportunityLineItems: fetchOpValues.opportunityLineItems,
        [EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.shippingIsPhysicalAddress, localStorageValues?.[EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL]),
        [EquipmentSetUpFields.SHIPPING_PHONE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.shippingPhone, localStorageValues?.[EquipmentSetUpFields.SHIPPING_PHONE]),
        [EquipmentSetUpFields.SHIPPING_CITY]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.shippingCity, localStorageValues?.[EquipmentSetUpFields.SHIPPING_CITY]),
        [EquipmentSetUpFields.SHIPPING_STATE]: getOpptyFields(US_STATES?.find(option => (option.value === fetchOpValues?.equipmentSetUpFields?.shippingState)), localStorageValues?.[EquipmentSetUpFields.SHIPPING_STATE]),
        [EquipmentSetUpFields.SHIPPING_ZIPCODE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.shippingZipCode, localStorageValues?.[EquipmentSetUpFields.SHIPPING_ZIPCODE]),
        [EquipmentSetUpFields.SHIPPING_ADD1]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.shippingAddr1, localStorageValues?.[EquipmentSetUpFields.SHIPPING_ADD1]),
        [EquipmentSetUpFields.SHIPPING_ADD2]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.shippingAddr2, localStorageValues?.[EquipmentSetUpFields.SHIPPING_ADD2]),
        [EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.blankGiftCardProvided, localStorageValues?.[EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]),
        [EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.balanceSheetProvided, localStorageValues?.[EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]),
        [EquipmentSetUpFields.ETHERNET_AVAILABLE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.ethernetAvailable, localStorageValues?.[EquipmentSetUpFields.ETHERNET_AVAILABLE]),
        [EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.feetCCMachineToRouter, localStorageValues?.[EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]),
        [EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.fivePortSwitchPurchase, localStorageValues?.[EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]),
        [EquipmentSetUpFields.POS_ACCESS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.posAccess, localStorageValues?.[EquipmentSetUpFields.POS_ACCESS]),
        [EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.handKeyTransactions, localStorageValues?.[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]),
        [EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.windowsTenOrNewer, localStorageValues?.[EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]),
        [EquipmentSetUpFields.UTILIZE_CARD_STORAGE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.utilizeCardStorage, localStorageValues?.[EquipmentSetUpFields.UTILIZE_CARD_STORAGE]),
        [EquipmentSetUpFields.UTILIZE_TIPS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.utilizeTips, localStorageValues?.[EquipmentSetUpFields.UTILIZE_TIPS]),
        [EquipmentSetUpFields.REPORTING_TRAINING]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.reportingTraining, localStorageValues?.[EquipmentSetUpFields.REPORTING_TRAINING]),
        [EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.noForReportingTraining, localStorageValues?.[EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]),
        [EquipmentSetUpFields.NO_OF_WORK_STATIONS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.noOfWorkStations, localStorageValues?.[EquipmentSetUpFields.NO_OF_WORK_STATIONS]),
        [EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.isAcceptingGiftCards, localStorageValues?.[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]),
        [EquipmentSetUpFields.GIFT_CARD_PROVIDER]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.giftCardProvider, localStorageValues?.[EquipmentSetUpFields.GIFT_CARD_PROVIDER]),
        [EquipmentSetUpFields.NO_OF_COMPUTERS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.noOfComputers, localStorageValues?.[EquipmentSetUpFields.NO_OF_COMPUTERS]),
        [EquipmentSetUpFields.PROCESSING_DATE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.processingDate, localStorageValues?.[EquipmentSetUpFields.PROCESSING_DATE]),
        [EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.isInterestedGiftCards, localStorageValues?.[EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]),
        [EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.installContactFirstName, localStorageValues?.[EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]),
        [EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.installContactLastName, localStorageValues?.[EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]),
        [EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.installContactEmail, localStorageValues?.[EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]),
        [EquipmentSetUpFields.INSTALL_CONTACT_PHONE]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.installContactPhone, localStorageValues?.[EquipmentSetUpFields.INSTALL_CONTACT_PHONE]),
        [EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]: getOpptyFields(fetchOpValues?.equipmentSetUpFields?.installAdminAccess, localStorageValues?.[EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]),
        // Set file upload field values. Disregard local storage values in event that file is removed from another device.
        [BankFields.P_BANK_ACC_FILE]: fetchOpValues.bankDetails.primaryAccountFile,
        [BankFields.ALT_BANK_ACC_FILE]: fetchOpValues.bankDetails.altAccountFile,
        [BankFields.GUARANTEE_STATEMENT1]: fetchOpValues.bankDetails.guaranteeStatement1,
        [BankFields.GUARANTEE_STATEMENT2]: fetchOpValues.bankDetails.guaranteeStatement2,
        [BankFields.GUARANTEE_STATEMENT3]: fetchOpValues.bankDetails.guaranteeStatement3,
        [BankFields.GUARANTEE_B_SHEET]: fetchOpValues.bankDetails.guaranteeBalanceSheet,
        [BankFields.GUARANTEE_PL_STATEMENT]: fetchOpValues.bankDetails.guaranteePLStatement,
        [BankFields.EXTRA_FILES]: fetchOpValues.bankDetails.extraFiles,
        [ProcessingFields.MARKETING_FILE]: fetchOpValues.processingDetails.marketingFile,
        [ProcessingFields.CONTRACT_FILE]: fetchOpValues.processingDetails.contractFile,
    }
    return {
        ...localStorageValues,
        ...formData,
    }
};

type ProductConfig = { hasEIServerOrCloud: boolean, hasEmergepay: boolean }
/**
 * Map all form field values to MAE API request body
 * @param fields object representing all form values
 * @param productConfig object representing which products Opportunity is configured with
 * @returns object representing full application sent to API's submit endpoint
 */
export const mapFormValuesToAPI = (fields: AllFields, productConfig: ProductConfig) => {

    const { hasEIServerOrCloud, hasEmergepay } = productConfig;

    const address = {
        line1: fields.businessAddr1,
        line2: fields.businessAddr2,
        city: fields.businessCity,
        state: fields.businessState?.value,
        postalCode: fields.businessZipCode,
    };
    const legalAddress = fields.legalIsPhysicalAddress === 'yes' ? address : {
        line1: fields.legalAddr1,
        line2: fields.legalAddr2,
        city: fields.legalCity,
        state: fields.legalState?.value,
        postalCode: fields.legalZipCode,
    };

    const isSoleProp = fields.entityType?.value === 'Sole Proprietorship';
    const people = isSoleProp ? [{
        address: {
            line1: fields.people?.[0].Addr1,
            line2: fields.people?.[0].Addr2,
            city: fields.people?.[0].City,
            state: fields.people?.[0].State?.value,
            postalCode: fields.people?.[0]?.ZipCode,
        },
        dob: fields.people?.[0].DateOfBirth,
        email: fields.people?.[0].Email,
        firstName: fields.people?.[0].FirstName,
        lastName: fields.people?.[0].LastName,
        percentOwnership: 100,
        phone: fields.people?.[0].ContactNumber,
        ...(fields.people?.[0].SSN && !fields.people?.[0].SSN?.includes('*') ? { ssn: fields.people[0].SSN } : {}),
        title: fields.people?.[0].Title?.value,
        hasBusinessAddress: fields.people?.[0].isPrimaryLocSameAsBusiness,
    }] : fields.people?.map(person => ({
        address: {
            line1: person?.Addr1,
            line2: person?.Addr2,
            city: person?.City,
            state: person?.State?.value,
            postalCode: person?.ZipCode,
        },
        dob: person.DateOfBirth,
        email: person.Email,
        firstName: person.FirstName,
        lastName: person.LastName,
        percentOwnership: person.PercentOwnership,
        phone: person.ContactNumber,
        ...(person.SSN && !person.SSN?.includes('*') ? { ssn: person.SSN } : {}),
        title: person.Title?.value,
        hasBusinessAddress: person.isPrimaryLocSameAsBusiness,
    }));

    const files: DocumentFile[] = [];
    if (fields.primaryAccountFile) {
        files.push({ ...fields.primaryAccountFile, type: 'Voided Check' });
    }
    if (fields.altAccountFile) {
        files.push({ ...fields.altAccountFile, type: 'Internal File' });
    }
    fields?.people?.forEach(person => {
        if (person?.PhotoID) {
            const prefix = `${person.FirstName}-${person.LastName}`;
            const name = person.PhotoID.name.startsWith(prefix) ? person.PhotoID.name : `${prefix}-${person.PhotoID.name}`;
            files.push({ ...person.PhotoID, name, type: 'Internal File' });
        }
    })
    if (fields?.extraFiles?.length) {
        files.push(...fields.extraFiles.map(file => ({ ...file, type: 'Other' as IFile__c['Des__c'] })));
    }
    if (fields.contractFile) {
        files.push({ ...fields.contractFile, type: 'Other' });
    }
    if (fields.marketingFile) {
        files.push({ ...fields.marketingFile, type: 'Other' });
    }

    if (fields.waivePersonalGuarantee === 'yes') {
        if (fields.guaranteeStatement1) {
            files.push({ ...fields.guaranteeStatement1 as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteeStatement2) {
            files.push({ ...fields.guaranteeStatement2 as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteeStatement3) {
            files.push({ ...fields.guaranteeStatement3 as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteeBalanceSheet) {
            files.push({ ...fields.guaranteeBalanceSheet as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteePLStatement) {
            files.push({ ...fields.guaranteePLStatement as DocumentFile, type: 'Financials' })
        }
    }

    return {
        banking: {
            ...(fields[BankFields.P_BANK_ACCOUNT_NUMBER] && !fields[BankFields.P_BANK_ACCOUNT_NUMBER]?.includes('*') ? { primaryBankAccountNumber: fields[BankFields.P_BANK_ACCOUNT_NUMBER] } : {}),
            primaryRoutingNumber: fields[BankFields.P_BANK_ROUTING_NUMBER],
            primaryBankIsChase: fields.primaryBankIsChase,
            primaryBankValidatedByPlaid: fields.primaryBankValidatedByPlaid,
            isPersonalGuarantee: fields[BankFields.WAIVE_PERSONAL_GUARANTEE] === 'no',
            ...(fields[BankFields.ALT_BANK_ACCOUNT_NUMBER] && !fields[BankFields.ALT_BANK_ACCOUNT_NUMBER]?.includes('*') ? { altBankAccountNumber: fields[BankFields.ALT_BANK_ACCOUNT_NUMBER] } : {}),
            altBankAccountNumber: fields[BankFields.ALT_BANK_ACCOUNT_NUMBER],
            altRoutingNumber: fields[BankFields.ALT_BANK_ROUTING_NUMBER],
            altBankIsChase: fields.altBankIsChase,
            altBankValidatedByPlaid: fields.altBankValidatedByPlaid,
            useAlternativeAccount: fields[BankFields.USE_ALTERNATIVE_ACCOUNT]?.toLowerCase() === 'yes',
        },
        business: {
            address,
            dba: fields.dbaName,
            email: fields.businessEmail,
            phone: fields.businessPhone,
            websiteUrl: fields.businessUrl,
        },
        closeDate: fields.targetDate,
        cnpAgreement: fields[ProcessingFields.IS_CNP_REQUIRED] === 'yes' ? {
            creditAuthDuration: fields[ProcessingFields.TXN_COMPLETE_PERIOD]?.value,
            otherCreditAuthDuration: fields[ProcessingFields.TXN_COMPLETE_PERIOD_OTHER],
            doesCustomerSignContract: fields[ProcessingFields.DOES_CUS_SIGN_CONTRACT] === 'yes',
            howCustomerOrderProduct: fields[ProcessingFields.HOW_CUSTOMER_ORDER],
            isPciCompliant: fields[ProcessingFields.PCI_COMPLIANT]?.value !== '' ? fields[ProcessingFields.PCI_COMPLIANT]?.value === 'yes' : null,
            productAdvertisedOn: fields[ProcessingFields.MARKETING_WEBSITE], // TODO(LY) Confirm this is correct
            productServiceProvided: fields[ProcessingFields.SERVICE_FOR_CC_HOLDERS],
            recurringBillingDuration: fields[ProcessingFields.BILLING_PERIOD]?.value ?? null,
            storesCardNumbers: fields[ProcessingFields.DB_COLLECTING_CC_NUMBERS] === 'yes',
            vendorNamesAndAddress: fields[ProcessingFields.VENDOR_PUR_ADDRESS],
        } : null,
        delivery: {
            delay_1_3_days: fields.delivery_1_3_DaysPercentage,
            delay_4_7_days: fields.delivery_4_7_DaysPercentage,
            delay_8_14_days: fields.delivery_8_14_DaysPercentage,
            delay_15_30_days: fields.delivery_15_30_DaysPercentage,
            delay_over_30_days: fields.delivery_Over_30_DaysPercentage,
            immediate: fields.delivery_immediate_Percentage,
            refundPolicyInDays: fields.daysToReturnOrCancel,
            isGSDeliveryAfterTxnDay: fields[ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY]
        },
        files,
        legalEntity: {
            address: legalAddress,
            dateOfIncorporation: fields.dateOfEstb,
            name: fields.legalName,
            tin: `${fields.tin}`,
            tinType: fields.businessTaxPayMethod,
            type: fields.entityType?.value,
        },
        locationCount: fields.locationCount,
        people,
        shippingDetails: fields.shippingIsPhysicalAddress === 'yes' ?
            `Address Line 1: ${fields.businessAddr1}
            Address Line 2: ${fields.businessAddr2}
            City: ${fields.businessCity}
            State: ${fields.businessState?.value}
            Zip Code: ${fields.businessZipCode}
            Phone: ${fields.businessPhone}`
            :
            `Address Line 1: ${fields.shippingAddr1}
            Address Line 2: ${fields.shippingAddr2}
            City: ${fields.shippingCity}
            State: ${fields.shippingState?.value}
            Zip Code: ${fields.shippingZipCode}
            Phone: ${fields.shippingPhone}`,
        installNotes:
            `Install / Training Point of Contact First Name: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]}
            Install / Training Point of Contact Last Name: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]}
            Install / Training Point of Contact Phone: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_PHONE]}
            Install / Training Point of Contact Email: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]}
            ${(hasEIServerOrCloud || hasEmergepay) ? `
                Will you be utilizing tips?: ${fields[EquipmentSetUpFields.UTILIZE_TIPS]}
            ` : ``}
            ${hasEIServerOrCloud ? `
                Do you plan to utilize card storage?: ${fields[EquipmentSetUpFields.UTILIZE_CARD_STORAGE]}
                Can you confirm that your computer is currently running Windows 10 or newer?: ${fields[EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]}
                Is there a wired internet connection (ethernet) available?: ${fields[EquipmentSetUpFields.ETHERNET_AVAILABLE]}
                Feet from credit card machine to modem/router: ${fields[EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]}
                Would you like to purchase a 5-port switch ($21) from Gravity Payments?: ${fields[EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]}
                Do you remote into a server to access the Point of Sale?: ${fields[EquipmentSetUpFields.POS_ACCESS]}
                Would you like the ability to hand-key transactions on any other computers without a physical terminal?: ${fields[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]}
                How many computers?: ${fields[EquipmentSetUpFields.NO_OF_COMPUTERS]}
                Do you currently accept gift cards?: ${fields[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]}
                Current Gift Card Provider: ${fields[EquipmentSetUpFields.GIFT_CARD_PROVIDER]}
                Balance Sheet Provided to Gravity: ${fields[EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]}
                Blank Gift Card Provided to Gravity: ${fields[EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]}
                Are you interested in accepting gift cards?: ${fields[EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]}
                How many workstations (terminal and hand-key) will process cards with Gravity Payments?: ${fields[EquipmentSetUpFields.NO_OF_WORK_STATIONS]}
                During the install, will somebody have admin access (usernames/passwords) to the workstations?: ${fields[EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]}
            ` : ``}
            ${(hasEIServerOrCloud || hasEmergepay) ? `Is there more than one person (or a different person) who will need training on reporting?: ${fields[EquipmentSetUpFields.REPORTING_TRAINING]}
            How many people will need training?: ${fields[EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]}
            When do you anticipate processing your first transaction with Gravity Payments?: ${fields[EquipmentSetUpFields.PROCESSING_DATE]}` : ``}`,
        equipmentSetUpFields: {
            shippingIsPhysicalAddress: fields.shippingIsPhysicalAddress,
            shippingAddr1: fields.shippingAddr1,
            shippingAddr2: fields.shippingAddr2,
            shippingCity: fields.shippingCity,
            shippingState: fields.shippingState?.value,
            shippingZipCode: fields.shippingZipCode,
            shippingPhone: fields.shippingPhone,
            installContactFirstName: fields[EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME],
            installContactLastName: fields[EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME],
            installContactPhone: fields[EquipmentSetUpFields.INSTALL_CONTACT_PHONE],
            installContactEmail: fields[EquipmentSetUpFields.INSTALL_CONTACT_EMAIL],
            utilizeTips: fields[EquipmentSetUpFields.UTILIZE_TIPS],
            utilizeCardStorage: fields[EquipmentSetUpFields.UTILIZE_CARD_STORAGE],
            windowsTenOrNewer: fields[EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER],
            ethernetAvailable: fields[EquipmentSetUpFields.ETHERNET_AVAILABLE],
            feetCCMachineToRouter: fields[EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER],
            fivePortSwitchPurchase: fields[EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE],
            posAccess: fields[EquipmentSetUpFields.POS_ACCESS],
            handKeyTransactions: fields[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS],
            noOfComputers: fields[EquipmentSetUpFields.NO_OF_COMPUTERS],
            isAcceptingGiftCards: fields[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS],
            giftCardProvider: fields[EquipmentSetUpFields.GIFT_CARD_PROVIDER],
            balanceSheetProvided: fields[EquipmentSetUpFields.BALANCE_SHEET_PROVIDED],
            blankGiftCardProvided: fields[EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED],
            isInterestedGiftCards: fields[EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS],
            noOfWorkStations: fields[EquipmentSetUpFields.NO_OF_WORK_STATIONS],
            installAdminAccess: fields[EquipmentSetUpFields.INSTALL_ADMIN_ACCESS],
            reportingTraining: fields[EquipmentSetUpFields.REPORTING_TRAINING],
            noForReportingTraining: fields[EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING],
            processingDate: fields[EquipmentSetUpFields.PROCESSING_DATE],
        }
    };
};
