import styled, { useTheme } from "styled-components";
import Select, {
    components,
    DropdownIndicatorProps,
    Props,
} from "react-select";
import CaretDown from "@components/icons/CaretDown";
import { useCallback } from "react";

const DropdownIndicator = (props: DropdownIndicatorProps<unknown, true>) => {
    const theme = useTheme();

    return (
        <components.DropdownIndicator {...props}>
            <CaretDown fill={theme?.pewter} />
        </components.DropdownIndicator>
    );
};

const SelectComponent = styled(Select)`
    font-size: 14px;
    div[class$="-control"] {
        background: ${(props) => props.theme?.input?.backgroundColor};
        border: ${(props) => props.theme?.input?.border} !important;
    }
    div[class$="-indicatorContainer"] {
        padding: 6px;
    }
    &.required-with-error > div {
        background-color: ${(props) => props.theme?.requiredWithError.backgroundColor} !important;
        border: ${(props) => props.theme?.requiredWithError.border} !important;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyOption = (props: any) => {
    const { selectProps, data } = props;
    const input = selectProps.inputValue;
    const option = data.label;
    const getLabel = () => {
        const num = option.toLowerCase()?.indexOf(input.toLowerCase());
        const len = input.length;

        if (num < 0) {
            return option;
        }

        return (
            <div>
                <span>{option.substr(0, num)}</span>
                <span className="fw-bold">{option.substr(num, len)}</span>
                <span>{option.substr(num + len)}</span>
            </div>
        );
    };

    return <components.Option {...props}>{getLabel()}</components.Option>;
};

const SelectInput = ({
    placeholder = "Select",
    options,
    autoComplete = undefined,
    menuPlacement = "auto",
    menuPortalTarget = document.getElementById('app-container') as HTMLElement,
    menuPosition = "fixed",
    ...props
}: Props & { autoComplete?: string }) => {
    const Input = useCallback(
        (InputProps: any) => (
            <components.Input {...InputProps} {...autoComplete && { autoComplete }} />
        ),
        [autoComplete]
    );

    return (
        <SelectComponent
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
                Option: MyOption,
                Input,
            }}
            instanceId={placeholder?.toString()}
            options={options}
            placeholder={placeholder}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            menuPlacement={menuPlacement}
            {...props}
        />
    );
};

export default SelectInput;
