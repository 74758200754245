import { IconProps } from "./type";

const Camera = ({ fill = "#107AB0", className }: IconProps) => (
        <svg
            width="24"
            height="22"
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M6.98438 2.04688C7.26562 1.15625 8.10938 0.5 9.09375 0.5H14.8594C15.8438 0.5 16.6875 1.15625 16.9688 2.04688L17.4844 3.5H21C22.6406 3.5 24 4.85938 24 6.5V18.5C24 20.1875 22.6406 21.5 21 21.5H3C1.3125 21.5 0 20.1875 0 18.5V6.5C0 4.85938 1.3125 3.5 3 3.5H6.46875L6.98438 2.04688ZM12 8C10.3594 8 8.90625 8.89062 8.0625 10.25C7.26562 11.6562 7.26562 13.3906 8.0625 14.75C8.90625 16.1562 10.3594 17 12 17C13.5938 17 15.0469 16.1562 15.8906 14.75C16.6875 13.3906 16.6875 11.6562 15.8906 10.25C15.0469 8.89062 13.5938 8 12 8Z"
                fill={fill}
            />
        </svg>
    );

export default Camera;
