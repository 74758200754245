const OwnerTypes = [
    { value: 'President', label: 'President' },
    { value: 'Vice President', label: 'Vice President' },
    { value: 'Treasurer', label: 'Treasurer' },
    { value: 'Owner', label: 'Owner' },
    { value: 'Partner', label: 'Partner' },
    { value: 'CEO', label: 'CEO' },
    { value: 'CFO', label: 'CFO' },
    { value: 'Secretary', label: 'Secretary' },
    { value: 'Director', label: 'Director' },
    { value: 'General Manager', label: 'General Manager' },
    { value: 'Other', label: 'Other' },
];

export default OwnerTypes;
