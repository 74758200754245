import { SVGProps } from 'react';

const FileUploadIcon = ({ fill = '#107AB0', className, ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="24"
            height="30"
            viewBox="0 0 24 30"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 0V7.5H24L16 0ZM14 7.5V0H3C1.34312 0 0 1.25918 0 2.8125V27.1875C0 28.7402 1.34312 30 3 30H21C22.6569 30 24 28.7408 24 27.1875V9.375H16.0562C14.8937 9.375 14 8.53711 14 7.5ZM18.0063 20.1621C17.7688 20.4902 17.3875 20.625 17 20.625C16.6125 20.625 16.2325 20.4877 15.9394 20.213L13.5 17.9238V23.9062C13.5 24.6826 12.8281 25.3125 12 25.3125C11.1719 25.3125 10.5 24.6826 10.5 23.9062V17.9238L8.06063 20.2107C7.47469 20.7601 6.52562 20.7601 5.93937 20.2107C5.35313 19.6614 5.35344 18.7717 5.93937 18.2221L10.9394 13.5346C11.5253 12.9853 12.4744 12.9853 13.0606 13.5346L18.0606 18.2221C18.6437 18.7734 18.6438 19.6641 18.0063 20.1621Z"
                fill={fill}
            />
        </svg>
    );
};

export default FileUploadIcon;
