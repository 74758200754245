import { DocumentFile } from '@common/inputs/FileInput';
import US_STATES from './static-options/state';

export const format2USAmount = (val?: number|string) => (val || val === 0) ? new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
}).format(Number(val)) : null;
export const getProductTransactionFeeClassName = (productId: string) => {
    // using a switch statement in case we need to expand this to more products in the future
    if (process.env.NEXT_PUBLIC_APP_ENV === 'app'){
        switch (productId) {
            case '01tPj000003LkNtIAK': // Clover Platform Fee
                return `hasDisclaimer cloverPlatformFeeTxnFee`;
            default:
                return '';
        }
    } else {
        switch (productId) {
            case '01tO3000004W0HAIA0': // Clover Platform Fee
                return `hasDisclaimer cloverPlatformFeeTxnFee`;
            default:
                return '';
        }
    }
}
export const currentYear = new Date().getFullYear();

export const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match && match?.length) {
        return `( ${match[1]} ) ${match[2]} - ${match[3]}`;
    }

    return phoneNumberString;
};

export const formatRate = (rate?: number) => rate ? `${rate.toFixed(2)}%` : null;

export const formatPrefillValueDropdowns = (salesforceValue: string) => {
    switch (salesforceValue) {
        case 'D - Checking':
            return 'checking';
        case 'S - Savings':
            return 'savings';
        case '1 - SSN':
            return 'SSN';
        case '2 - EIN':
            return 'EIN/TIN';
        default:
            return '';
    }
};

export const formatPrefillDate = (date?: string) => {
    if (date) {
        const parsedDate = date.split('-');
        return `${parsedDate[1]}/${parsedDate[2]}/${parsedDate[0]}`;
    }
};

export const getFileDetails = (file?: DocumentFile) => {
    const { name = '', url = '' } = file || {};

    if (!url || !name) {
        return null;
    }

    return <span>{name}</span>;
};

export const getAddressValue = (add1 = '', add2 = '', city = '', state = '', zipcode = '') => {
    if (!add1 || !city || !state || !zipcode) {
        return null;
    }

    return (
        <span>
            <div data-cy="addressLine1">{add1}</div>
            <div data-cy="addressLine2">{add2}</div>
            <div data-cy="cityStateZipcode">
                {city}, {state} {zipcode}
            </div>
        </span>
    );
};

/**
 * Given a string and mask character, mask string only revealing the last n chars, e.g. XXXXX6789
 * @param {string} str Plain-text string, e.g. "abcdefghijk"
 * @param {string} maskChar Character used for masking, e.g. "X"
 * @param {number} n How many characters to reveal at the end of string, e.g. 4
 * @returns Masked string, e.g. "XXXXXXXhijk"
 *
 * NOTE(BL): Must be function expression (vs declaration) for jest spying to work properly. Unknown why...
 */
export const maskString = (str: string, maskChar: string, n = 0): string => {
    if (n === 0) {
        return str.replace(/./g, maskChar);
    }

    return (str).slice(0, -n)
        .replace(/./g, maskChar)
        + (str).slice(-n);
}

export const getStateOption = (value?: string) => value ? US_STATES.find(state => state.value === value) : undefined;
